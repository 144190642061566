import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { auth } from "../config/Firebase";
import {onAuthStateChanged} from 'firebase/auth'
import Header from '../Components/Header';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function Profile() {
  const navigate = useNavigate();
  const user = auth.currentUser;
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [displayName, setDisplayName] = useState();
  const [emailName, setEmailName] = useState();
  const [photoURL, setPhotoURL] = useState();
  
  	const deleteSignedUser = async () => {
  
 
    const url = '../retro/delete_user.php';
	
	var formData = new FormData();
	formData.append("myUID", user.uid);
	
	const config = { 'content-type': 'application/json' };
	axios.post(url, formData, config)
	  .then(response => {})
            // User deleted.
			//localStorage.removeItem("loggedIn");
			user.delete()
			.then(() => {
            // User deleted.
			//localStorage.removeItem("loggedIn");
			navigate(
          "../"
        );
        })
}

const signOutUser = async () => {
 
    auth.signOut()
        .then(() => {
            // User deleted.
			//localStorage.removeItem("loggedIn");
			navigate(
          "../"
        );
        })
        .catch((error) => {
            // An error occurred
            // ...
        });
}

  useEffect(() => {
	 
	  onAuthStateChanged(auth, (user) => {
	if (user) {
		setIsSignedIn(true)
		setDisplayName(user.displayName);
		setEmailName(user.email);
		setPhotoURL(user.photoURL);
		console.log(displayName);
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
	
	
    // ...
  } else {
    navigate(
          "../",
          {
            replace: true,
          }
        );
  }
});
   
	  
	
  }, [navigate]);
  
    const submit = () => {

    confirmAlert({
      title: 'Confirm',
      message: 'Are You Sure? All your data will be deleted.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteSignedUser()
        },
        {
          label: 'No',
          //onClick: () => alert('Click No')
        }
      ]
    });
  }

    return (
	<div>
      <Header />
    {isSignedIn?(
      <section id="about">
      <div className="row">
         
         <div className="nine columns main-col">
		 <img className="profile-pic"  src={photoURL} alt="" />
		 <p>Name : {displayName}</p>
			<p>Email : {emailName}</p>
			
			<div className="box">
        <div className="divbox">
            
            <button className="mybtn" onClick={submit}>Delete Account</button>
        </div>
    </div>
	
	<div className="box">
        <div className="divbox">
            
            <button className="mybtn" onClick={signOutUser}>Sign Out</button>
        </div>
    </div>
	

            
          
         </div>
      </div>
   </section>
   ) : (
        console.log("not logged in")
      )}
   </div>
    );
}

export default Profile;