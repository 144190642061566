// Import React and ReactDOM
import React, { Component } from 'react'
import Header from '../Components/Header';

// Import jQuery
import $ from 'jquery'

// Import reCaptcha

// Import some simple styles for contact form
//import './styles/styles.css'

export default class ContactPage extends Component {
  state = {
    inputEmail: '',
    inputCheckBoth: false,
    inputCheckDesign: false,
    inputCheckDev: false,
    inputMessage: '',
    inputName: '',
    isCaptchaValid: false,
    isErrorShown: false,
    isFormValid: false
  }

  // Handle visitor's interaction with inputs
  handleInput = event => {
    // Test for input and length of the value
    if (event.target.value.length > 0 && event.target.name !== 'inputEmail') {
      this.setState({
        [event.target.name]: event.target.value
      })
    }

    // If input is for email address validate it with regexp
    if (event.target.name === 'inputEmail') {
      // eslint-disable-next-line
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (reg.test(String(event.target.value).toLowerCase())) {
        this.setState({
          [event.target.name]: event.target.value
        })
      }
    }
  }

  // Handle visitor's interaction with checkboxes
  handleCheckbox = event => {
    this.setState({
      [event.target.name]: event.target.checked
    })
  }


  handleFormSubmit = event => {
    event.preventDefault()

    // Test
    if (this.state.inputEmail.length > 0 && this.state.inputName.length > 0 && this.state.inputMessage.length > 0) {
      this.setState({
        isErrorShown: false,
        isFormValid: true,
		isFormSubmitted: true,
		
      })

      // Send the form with AJAX
      $.ajax({
        data: this.state,
        type: 'POST',
        url: 'mailer.php',
        success: function(data) {
          console.info(data)
        },
        error: function(xhr, status, err) {
          console.error(status, err.toString())
        }
      })

      // Reset state after sending the form
      this.setState({
        inputEmail: '',
        inputCheckBoth: false,
        inputCheckDesign: false,
        inputCheckDev: false,
        inputMessage: '',
        inputName: '',
        isErrorShown: false,
        isFormValid: false
      })
    } else {
      // Show error message
      this.setState({
        isErrorShown: true

      })
    }
  }

  render() {
    return (
	<div>
      <Header />
	<section id="contact">
	
	<div className="row">
         <div className="three columns">
		 
         </div>
         <div className="darkbackgrounddivcontact">
		 <h3>Contact</h3>
		<form action="">
          <fieldset>
            <label htmlFor="inputName">Name : </label>

            <input onChange={this.handleInput} type="text" name="inputName" id="inputName" required={true} />
          </fieldset>

          <fieldset>
            <label htmlFor="inputEmail">E-mail : </label>

            <input onChange={this.handleInput} type="email" name="inputEmail" id="inputEmail" placeholder="Your email." required={true} />
          </fieldset>

          <fieldset>
            <label htmlFor="inputMessage">Message : </label>

            <textarea onChange={this.handleInput} name="inputMessage" id="inputMessage" required={true} />
          </fieldset>

          {/* !! */}
          {/* Make sure to use your 'sitekey' for Google reCaptcha API! */}
          {/* !! */}
          

          {this.state.isFormSubmitted && (
            <fieldset>
              <p>Your message has been sent.</p>
            </fieldset>
          )}

          {this.state.isErrorShown && (
            <fieldset>
              <p>Please complete all fields correctly.</p>
            </fieldset>
          )}

          <fieldset>
		  
		  <div className="box">
        <div className="divbox">
            
            <button className="mybtn" onClick={this.handleFormSubmit}>Send</button>
        </div>
    </div>
		  
          </fieldset>
        </form>
         </div>
      </div>
	
	
	
	  </section>
	  </div>
    )
  }
}