/* global google */
import logo from './logoheader.png';
import './App.css';
import { useState, useEffect } from 'react'
import { jwtDecode } from "jwt-decode";
import {signInWithCredential, GoogleAuthProvider,getAuth, onAuthStateChanged} from 'firebase/auth'
import { auth } from "./config/Firebase";
import axios from 'axios';
import { useNavigate,Link,useLocation } from "react-router-dom";
import Header from './Components/Header';
import { Modal } from 'react-responsive-modal';

function SignUp() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null)
  let navigate = useNavigate();
  const { state } = useLocation();
  const [myUID, setMyUID] = useState();
  var roundsCount = 0;
  
  const [isLoading, setLoading] = useState(true);
  const [countRounds, setCountRounds] = useState();
  
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  
  const dataOnePlayer = { playerCount: 1 };
  const dataTwoPlayer = { playerCount: 2 };
  const dataThreePlayer = { playerCount: 3 };
  const dataFourPlayer = { playerCount: 4 };

  const onOneTapSignedIn = response => {
    setIsSignedIn(true)
    const decodedToken = jwtDecode(response.credential)
    setUserInfo({...decodedToken})
	
	const credential = signInWithCredential(auth, GoogleAuthProvider.credential(response.credential))
        .then((result) => {
            const {user} = result;
			const {_tokenResponse} = result;
			const uid  = auth?.currentUser?.uid;
			
			
			setMyUID(uid);
			console.log(state);
			
			countSets(uid);

			//localStorage.setItem('loggedIn', 'true')
			//console.log(response.credential)
			
			const url = '../retro/create_user.php';
			var formData = new FormData();
			formData.append("myUID", user.uid);
			formData.append("email", user.email);
			
			const config = { 'content-type': 'application/json' };
			axios.post(url, formData, config)
			.then(response => {
				console.log("onOneTapSignedIn");
			
        })
        })
        .catch((error) => {
            console.error("firebase error", error);
        });
  }

  const initializeGSI = () => {
    google.accounts.id.initialize({
      client_id: '140636753567-9n93s372dm35qq4q7nfcl7hhvosqf8ep.apps.googleusercontent.com',
      cancel_on_tap_outside: false,
      callback: onOneTapSignedIn
    });
    google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed()) {
        console.log(notification.getNotDisplayedReason())
      } else if (notification.isSkippedMoment()) {
        console.log(notification.getSkippedReason())
      } else if(notification.isDismissedMoment()) {
        console.log(notification.getDismissedReason())
      }
    });
  }
  
  const signout = async () => {
 
	//localStorage.removeItem("loggedIn");
    auth.signOut()
        .then(() => {
			
			window.location.reload();
        })
        .catch((error) => {
            // An error occurred
            // ...
        });
}

	const countSets = async (uid) => {
		var myUID = uid;
		const url = '../retro/count_sets.php';
	
		var formData = new FormData();
		formData.append("myUID", myUID);
	
		const config = { 'content-type': 'application/json' };
		axios.post(url, formData, config)
		.then(response => {const data =  response.data;
		
		//addStats(data.data[0].fullsets);
		roundsCount = data.data[0].fullsets;
		console.log(myUID);
		console.log(roundsCount);
		localStorage.setItem('rounds' , JSON.stringify(data.data[0].fullsets));
        })
	}

  useEffect(() => {
	  
	
	  onAuthStateChanged(auth, (user) => {
	if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
	setIsSignedIn(true)
    setUserInfo(user);
    const uid = user.uid;
	const email = user.email;
	
	setMyUID(uid);
	countSets(uid);
	
		var myUID = uid;
		const url = '../retro/count_sets.php';
	
		var formData = new FormData();
		formData.append("myUID", myUID);
	
		const config = { 'content-type': 'application/json' };
		axios.post(url, formData, config)
		.then(response => {const data =  response.data;
		
		
		
		//addStats(data.data[0].fullsets);
		roundsCount = data.data[0].fullsets;
		setCountRounds(roundsCount);
		setLoading(false);
		console.log(myUID);
		console.log(countRounds);
		localStorage.setItem('rounds' , JSON.stringify(data.data[0].fullsets));
        })
	console.log(user);
	
	
	
    // ...
  } else {
    const el = document.createElement('script')
    el.setAttribute('src', 'https://accounts.google.com/gsi/client')
    el.onload = () => initializeGSI();
    document.querySelector('body').appendChild(el)
  }
});
    
  }, [countRounds , myUID])

  if (isLoading) {
    return (<div>
      <Header />
	  <div className="App">
      <header className="App-header">
        
        
		<div className="playstorelogo">
		<a href="https://play.google.com/store/apps/details?id=com.randomhapps.golfscorecard" target="_blank">
		<img src="/images/playstore.png"  alt="Get it on Google Play" />
		</a>
		</div>
		
		
		</header>
		</div>
	  </div>
	  )
  }
  
  return (
  <div>
      <Header />
	  
    <div className="App">
      <header className="App-header">
        
        
		<div className="playstorelogo">
		<a href="https://play.google.com/store/apps/details?id=com.randomhapps.golfscorecard" target="_blank">
		<img src="/images/playstore.png"  alt="Get it on Google Play" />
		</a>
		</div>
		
		<div className="transparentbackgrounddiv">
		<div>
      {isSignedIn ? (
	  <div className="homebackgrounddiv">
		 
            <div className="row item">
			
		  <div className="tg-wrap"><table className="tg">
		  
				<tbody>
				  <tr>
					
					<td className="tg-baqh">
					<div>
					<div className="box">
        <div className="divbox">
            
            <button className="mybtn" onClick={onOpenModal}>New Round</button>
        </div>
    </div>
      
      <Modal open={open} onClose={onCloseModal} center
		classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}>
        <h2>How many players?</h2>
		<button><Link to="/new" state={dataOnePlayer}>1</Link></button>
		<button><Link to="/new" state={dataTwoPlayer}>2</Link></button>
		<button><Link to="/new" state={dataThreePlayer}>3</Link></button>
		<button><Link to="/new" state={dataFourPlayer}>4</Link></button>
      </Modal>
	  
	  
    </div>
					</td>
					<td className="tg-baqh">
					<div className="box">
        <div className="divbox">
            
            <button className="mybtn"><Link to={"/profile"}>Profile</Link></button>
        </div>
    </div>
					</td>
				  </tr>
				</tbody>
				</table></div>
	 
       
	 
            </div>
         </div>
		 
        
      ) : (
        console.log("not logged in")
      )}
    </div>
	
	
	
	
	<div>
      {countRounds > 0?(
	  <div className="homebackgrounddiv">
		 
            <div className="row item">
			
		  <div className="tg-wrap"><table className="tg">
		  
				<tbody>
				  <tr>
					
					
					<td className="tg-baqh">
					<div className="box">
        <div className="divbox">
            
            <button className="mybtn"><Link to={"/statistics"}>Statistics</Link></button>
        </div>
    </div>
					
					</td>
					<td className="tg-baqh">
					<div className="box">
        <div className="divbox">
            
            <button className="mybtn"><Link to={"/rounds"}>My Rounds ({countRounds})</Link></button>
        </div>
    </div>
					
			  </td>
				  </tr>
				</tbody>
				</table></div>
	 
       
	 
            </div>
         </div>
		 
        
      ) : (
        console.log("not logged in")
      )}
    </div>
	</div>
      </header>
	  
	  
	 
	  
	  
    </div>
	</div>
	
  );
  
  
  
  
  
  
}

export default SignUp;
