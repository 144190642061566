import React, { Component } from 'react';
import Header from '../Components/Header';

class About extends Component {
  render() {


    return (
	<div>
	<Header />
      <section id="about">
      <div className="row">
         <div className="three columns">
         </div>
         <div className="darkbackgrounddiv">
		<h3>Welcome to 18 Holes Golf Scorecard</h3>
            <p>Welcome to <strong>18 Holes Golf Scorecard</strong> — your ultimate golfing companion! Whether you're hitting the links with friends or tracking your performance, our app makes it easy to score and analyze your rounds of golf.</p>

            <h3>Effortless Scoring, Anytime, Anywhere</h3>
            <p>With our intuitive and user-friendly platform, you can quickly input scores for up to <strong>4 players</strong>. Whether you're playing a full 18-hole round of golf or enjoying a Pitch and Putt session, the <strong>18 Holes Golf Scorecard</strong> is designed for flexibility and ease. Our app seamlessly saves your progress, ensuring that you never lose track of your scores. Plus, with <strong>cross-platform</strong> compatibility (Web and Android), you can access your rounds and statistics from multiple devices — whenever you need them.</p>

            <h3>Key Features:</h3>
            <ul>
                <li><strong>Track Your Game:</strong> Effortlessly record scores for up to 4 players.</li>
                <li><strong>Sync Across Devices:</strong> Log in and view your rounds across multiple devices.</li>
                <li><strong>Detailed Statistics:</strong> Store and review your round statistics to monitor your improvement over time.</li>
                <li><strong>Automatic Saving:</strong> All your data is saved in real time — never worry about losing progress again.</li>
                <li><strong>Pitch and Putt Ready:</strong> Not just for full golf rounds, but perfect for Pitch and Putt games as well.</li>
            </ul>

            <h3>Why Choose 18 Holes Golf Scorecard?</h3>
            <p>Our goal is to simplify the process of tracking your golf scores and performance. Whether you're an amateur or an experienced golfer, the app helps you focus on what matters most: your game.</p>
            <p>Join the community of golfers who trust <strong>18 Holes Golf Scorecard</strong> to elevate their experience. No more complicated scorecards or lost data — just effortless tracking at your fingertips.</p>
        
         </div>
      </div>

   </section>
   </div>
    );
  }
}

export default About;
