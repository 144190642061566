import React, { useState, useEffect } from 'react';
import ScorecardView from './ScorecardView';
import Header from '../Components/Header';
import axios from 'axios';
import {
  useParams,
} from "react-router-dom";
import { auth } from "../config/Firebase";
import {onAuthStateChanged} from 'firebase/auth'
import { useNavigate } from "react-router-dom";

const RoundDisplayPage = () => {
	const user = auth.currentUser;

	const [posts, setPosts] = useState([]);
	
	let  { roundId } = useParams();
	
	const navigate = useNavigate();

    useEffect(() => {
		
	  onAuthStateChanged(auth, (user) => {
	if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
	const url = '../retro/findonegigjson.php';
	
	var formData = new FormData();
	formData.append("myUID", user.uid);
	formData.append("pid", roundId);
	
	const config = { 'content-type': 'application/json' };
    const fetchPosts = async () => {
      await axios.post(url, formData, config)
	  .then(res => {const data =  res.data;
	  
	  //console.log(totalPosts);

      setPosts(data);});
    };

    fetchPosts();
	
    // ...
  } else {
    navigate(
          "../",
          {
            replace: true,
          }
        );
  }
});
    
       
	
		
		
		
		
    }, [roundId]);

    

    return(
	<>
	<Header />
        <ScorecardView 
		    posts={posts}
        />
		</>
    );

};

export default RoundDisplayPage;