import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import '../App.css';
import '../styles/modalstyle.css';
import {onAuthStateChanged} from 'firebase/auth'
import { useNavigate,Link } from "react-router-dom";
import { auth} from "../config/Firebase";
import Header from '../Components/Header';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function Gigs() {
    const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  var uid;
  const navigate = useNavigate();
  

  

  useEffect(() => {
	  	  
	  onAuthStateChanged(auth, (user) => {
	if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
	
	const url = '../retro/findgigsjson.php';
	uid  = auth?.currentUser?.uid;
	
	var formData = new FormData();
	formData.append("myUID", uid);
	formData.append("myLimit", 100);
	
	const config = { 'content-type': 'application/json' };
    const fetchPosts = async () => {
      await axios.post(url, formData, config)
	  .then(res => {const data =  res.data;
	  
	  //console.log(totalPosts);

      setPosts(data);});
    };

    fetchPosts();
	
	
    // ...
  } else {
    // User is signed out
    // ...
  }
});
    
	  
  }, [navigate]); // Fetch the next page of posts when the current page changes


  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  

  



  return (
    <div className="container">
      <PostList posts={currentPosts} />
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );


	

  
}

const PostList = ({ posts }) => {
	const navigate = useNavigate();
	  const deleteRound = (event, uid,timestamp,parNumbersForMysql,playerOneScoresForMysql) => {
		  
		  console.log(parNumbersForMysql);
		  console.log(playerOneScoresForMysql);

    confirmAlert({
      title: 'Confirm',
      message: 'Are You Sure? This round will be deleted.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteRoundConfirm(event, uid,timestamp,parNumbersForMysql,playerOneScoresForMysql)
        },
        {
          label: 'No',
          //onClick: () => alert('Click No')
        }
      ]
    });
  }
  
      const deleteRoundConfirm = async (event, uid,timestamp,parNumbersForMysql,playerOneScoresForMysql) => {
  
 
    const url = '../retro/delete_round.php';
	
	var formData = new FormData();
	formData.append("myUID", uid);
	formData.append("documentName", timestamp);
	
	const config = { 'content-type': 'application/json' };
	axios.post(url, formData, config)
	  .then(response => {})
            // User deleted.
			//localStorage.removeItem("loggedIn");
			//user.delete();
			deleteStats(uid,parNumbersForMysql,playerOneScoresForMysql);
			
}

	const deleteStats = async (uid,parNumbersForStats,playerOneScoresForStats) => {
		
		let holesinone = 0,albatross = 0,eagles = 0,birdies = 0,pars = 0,bogeys = 0,doublebogies = 0,triplebogies = 0,worsethantriplebogies = 0;
		    for (let i = 0; i <= playerOneScoresForStats.length - 1; i++) {
            if (playerOneScoresForStats[i] == 1) {
                holesinone++;
            } else if (playerOneScoresForStats[i] > 1) {

                if (parNumbersForStats[i] == playerOneScoresForStats[i]) {
                    pars++;
                } else if (parNumbersForStats[i] > playerOneScoresForStats[i]) {
                    let x = Math.abs(parNumbersForStats[i] - playerOneScoresForStats[i]);
                    if (x == 1) {
                        birdies++;
                    } else if (x == 2) {
                        eagles++;
                    } else if (x == 3) {
                        albatross++;
                    } else if (x > 3) {
                        worsethantriplebogies++;
                    }
                } else if (playerOneScoresForStats[i] > parNumbersForStats[i]) {
                    let x = Math.abs(playerOneScoresForStats[i] - parNumbersForStats[i]);
                    if (x == 1) {
                        bogeys++;
                    } else if (x == 2) {
                        doublebogies++;
                    } else if (x == 3) {
                        triplebogies++;
                    } else if (x > 3) {
                        worsethantriplebogies++;
                    }
                }
            }
        }
			
			const url = '../retro/delete_stats.php';
			var formData = new FormData();
			formData.append("sets", posts.length);
			formData.append("myUID", uid);
			formData.append("holesinone", holesinone);
			formData.append("albatross", albatross);
			formData.append("eagles", eagles);
			formData.append("birdies", birdies);
			formData.append("pars", pars);
			formData.append("bogeys", bogeys);
			formData.append("doublebogies", doublebogies);
			formData.append("triplebogies", triplebogies);
			formData.append("worsethantriplebogies", worsethantriplebogies);
			
			const config = { 'content-type': 'application/json' };
			axios.post(url, formData, config)
			.then(response => {
				navigate(0);
			
        })
		console.log(posts.length);
	}
  
  return (
  <div>
      <Header />
      <section id="rounds">
	  
	  
      <div className="row education">
         <div className="three columns header-col">
            <h2><span>Rounds</span></h2>
			
         </div>
		 

         <div className="nine columns main-col">
		 
	 
		 
            <div className="row item">
			
               { posts.map(post =>  
			   
			   <li  key={post.pid}>
                {(() => {
        if (post.pid.length > 1) {
          return (
		  
		  
		  

		  
		  
		  <div class="tg-wrap"><table class="tg">
		  
				
				<tbody>
				  <tr>
					
					<td class="tg-baqh">
					<p> {post.date.substring(8,10)}{post.date.substring(7,8)}{post.date.substring(5,7)}{post.date.substring(4,5)}{post.date.substring(0,4)}</p>
					</td>
					<td class="tg-baqh">
					<p><a className = "venue-name"  > {post.courseName}</a></p>
					</td>
					<td class="tg-baqh">
					<p><i className="fa fa-user fa-1x">&nbsp;</i>{post.countPlayersOnStart}</p>
					</td>
					<td class="tg-baqh">
					<a ><i className="fa fa-info-circle fa-1x">&nbsp;</i>
                <Link to={`/${post.pid}`}>Details</Link>
              </a>
			  </td>
			  <td class="tg-baqh">
					<a >
				<button onClick={(e) => {
					deleteRound(e, post.myUID,post.timestamp,post.parNumbersForMysql,post.playerOneScoresForMysql);
					}}>
						X
				</button>
              </a>
			  
			  </td>
				  </tr>
				</tbody>
				</table></div>
	 
       )
        }
		else if (post.pid.length < 1) {
          return (
            
		   <div class="tg-wrap"><table class="tg">

<tbody>
  <tr>
    <td class="tg-baqh">
					
					</td>
  </tr>
</tbody>
</table></div>
		  
		  
		  
          )
        } else {
          return (
            <div>catch all</div>
          )
        }
        })()}
            </li>
		 
      )
	  
     }
	 
            </div>
         </div>
      </div>

   </section>
   </div>
    
  );
};

const Pagination = ({
  postsPerPage,
  totalPosts,
  setCurrentPage,
  currentPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber, e) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
	<div>
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <a
              onClick={(e) => paginate(number, e)}
              href="!#"
              className="page-link"
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
	</div>
  );
};

export default Gigs;